body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

.ant-timeline-item-last .ant-timeline-item-content {
  min-height: 24px;
}
 
.ant-layout {
  background: #e8f4fe;
}




.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.ant-layout {
  background: #e8f4fe;
}

.logo {
  /*
  width: 100px;
  height: 85px;
  background-size: 100px;
  */
  height: 70px;
  width: 80px;
  background-size: 75px;
  background-repeat: no-repeat;
  background-image: url('/images/siu-logo.png');
  margin: 0px 24px 10px 30px;
  float: left;
  /*
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.23);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.23);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.23);
  */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container {
  display: flex;
  justify-content: center;
}

.ant-timeline-item-last .ant-timeline-item-content {
  min-height: 24px;
}

.table-auto-width {
  width: "50%";
}

.ql-toolbar.ql-snow {

}

