body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

.ant-timeline-item-last .ant-timeline-item-content {
  min-height: 24px;
}
 
.ant-layout {
  background: #e8f4fe;
}

